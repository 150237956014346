import React from "react";
import Select from "react-select";

import "./ReactSelect.scss";
import SvgIcons from "./SVGIcons";

const ReactSelect = ({
  options,
  value,
  onChange,
  className,
  placeholder,
  name,
  label,
  touched,
  error,
  getOptionLabel,
  getOptionValue,
}) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: "calc(1.5em + .75rem + 2px)", // Adjust to match form-control height
      minHeight: "38px", // Adjust to match form-control min-height
    }),
  };

  //making sure options is and array and support map
  if (options?.map === undefined) {
    console.warn(
      `provided option is not an array. check reactSelect component ${JSON.stringify(
        options
      )}`
    );
    return;
  }
  const formattedOptions = options?.map((option) => ({
    value: option.value || option.id,
    label: option.label || option.title || "n",
    isDisabled: option.isDisabled,
  }));

  const selectedOption = formattedOptions.find((opt) => opt.value === value);

  return (
    <div className={`form-floating ${className}`}>
      <Select
        styles={customStyles}
        name={name}
        value={selectedOption}
        onChange={(option) =>
          onChange({ target: { name, value: option ? option.value : "" } })
        }
        options={formattedOptions}
        // placeholder={placeholder}
        placeholder=""
        isClearable
        classNamePrefix="react-select"
        className={`react-select-container ${
          selectedOption ? "has-value" : ""
        }`}
        components={{
          // ClearIndicator: () => null,
          DropdownIndicator: () => (
            <div className="react-select__dropdown-indicator p-2">
              {SvgIcons.reactSelectArrow}
            </div>
          ),
          IndicatorSeparator: () => null,
        }}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        // menuIsOpen
      />
      <label htmlFor={name} className={selectedOption ? "active" : ""}>
        {label}
      </label>
      {touched && error && (
        <small id="emailHelp" className="form-text text-danger">
          {error}
        </small>
      )}
    </div>
  );
};

export default ReactSelect;

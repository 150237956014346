export const GET_MASTER_DATA = "v1/masterdata";

/**
 * pouch bonus and subscriptions list
 *
 */
export const GET_POUCH_SETTINGS_LIST = "v1/settings";
export const GET_BONUS_SETTINGS = "v1/settings/";
export const UPDATE_SUBSCRIPTION_BY_ID = "v1/settings/subsciption/";
export const UPDATE_BONUS_BY_ID = "v1/settings/bonus/";

/**
 * cms module end points
 */
export const GET_CMS_LIST = "v1/cms";
export const GET_CMS_BY_ID = "v1/cms/";
export const UPDATE_CMS = "v1/cms/update/";

/**
 * Challanges
 */
export const GET_USER_CHALLENGE_BY_ID =
  "v1/challanges/user-assigned-challenges/";
export const GET_ADMIN_CREATED_LIST = "v1/challanges";
export const GET_ADMIN_CREATED_CHALLANGE_BY_ID = "v1/challanges/";
export const GET_BEHAVIOUR_LIST = "v1/challanges/behaviour-list/behaviour";
export const GET_CHALLANGES_LIST = "v1/challanges/challange-list/challanges";
export const ADD_CHALLANGE = "v1/challanges/add";
export const UPDATE_CHALLANGE_BY_ID = "v1/challanges/update/";
export const UPDATE_CHALLANGE_STATUS = "v1/challanges/status/";

/**
 * Template module end points
 */
export const GET_TEMPLATE_TYPE_LIST = "v1/templates/types/";
export const GET_TEMPLATE_LIST = "v1/templates";
export const GET_TEMPLATE_BY_ID = "v1/templates/";
export const ADD_TEMPLATE = "v1/templates/add";
export const UPDATE_TEMPLATE = "v1/templates/update/";
export const DELETE_TEMPLATE = "v1/templates/delete/";
export const UPDATE_TEMPLATE_STATUS = "v1/templates/status/";

/**
 * cms module end points
 */

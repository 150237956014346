import { Formik, Form as FormikForm } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormInput from "../../components/FormInput";
import { validationSchema } from "../../validations/goals.validation";
import {
  handleAddGoal,
  handleGetGoal,
  handleUpdateGoal,
} from "../../services/goal.service";
import toast from "react-hot-toast";
import SvgIcons from "../../components/SVGIcons";

const GoalModal = ({ modal, toggle, isEditMode, selectedGoal, dataQuery }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const imageRef = React.useRef(null);
  const onSubmit = async (values, actions) => {
    const formData = new FormData();

    if (uploadedImage) {
      formData.append("image", uploadedImage);
    }
    formData.append("title", values.title);
    formData.append("amount", values.amount);

    if (isEditMode) {
      handleUpdateGoal(selectedGoal.id, formData).then((response) => {
        toggle();
        toast.success("Goal updated successfully.");
        dataQuery.refetch();
      });
    } else {
      handleAddGoal(formData).then((response) => {
        toggle();
        toast.success("Goal created successfully.");
        dataQuery.refetch();
      });
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    // file size not grater than 1MB

    if (!file) return;

    if (file && file.size >= 1 * 1024 * 1024) {
      toast("Image should be allowed up to 1 MB");
      event.target.value = null;
      return;
    }

    setUploadedImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setImagePreview(null);
  }, [modal]);

  return (
    <Modal
      size="md"
      className="goal-modal-cover"
      isOpen={modal}
      toggle={toggle}
      centered
    >
      <Formik
        initialValues={{
          title: selectedGoal?.title || "",
          amount: selectedGoal?.amount || "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <>
            <ModalHeader className="border-0 fs-16 fw-semibold" toggle={toggle}>
              {isEditMode ? "Edit" : "Add"} Goal
            </ModalHeader>
            <ModalBody>
              <FormikForm>
                <Row>
                  <Col md={4} className="">
                    <div
                      className="goal-image position-relative"
                      onClick={() => {
                        imageRef.current.click();
                      }}
                    >
                      {imagePreview || selectedGoal?.image ? (
                        <img
                          src={imagePreview || selectedGoal?.image}
                          alt="goal"
                        />
                      ) : (
                        SvgIcons.imagePreview
                      )}
                      <div
                        className="edit-icon"
                        // onClick={() => {
                        //   imageRef.current.click();
                        // }}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="15" cy="15" r="15" fill="#FFC73B" />
                          <mask
                            id="mask0_2037_3252"
                            maskUnits="userSpaceOnUse"
                            x="6"
                            y="6"
                            width="18"
                            height="18"
                          >
                            <rect
                              x="6"
                              y="6"
                              width="18"
                              height="18"
                              fill="#D9D9D9"
                            />
                          </mask>
                          <g mask="url(#mask0_2037_3252)">
                            <path
                              d="M8.25 21.75V18.5625L18.15 8.68125C18.3 8.54375 18.4656 8.4375 18.6469 8.3625C18.8281 8.2875 19.0188 8.25 19.2188 8.25C19.4187 8.25 19.6125 8.2875 19.8 8.3625C19.9875 8.4375 20.15 8.55 20.2875 8.7L21.3188 9.75C21.4688 9.8875 21.5781 10.05 21.6469 10.2375C21.7156 10.425 21.75 10.6125 21.75 10.8C21.75 11 21.7156 11.1906 21.6469 11.3719C21.5781 11.5531 21.4688 11.7188 21.3188 11.8688L11.4375 21.75H8.25ZM19.2 11.85L20.25 10.8L19.2 9.75L18.15 10.8L19.2 11.85Z"
                              fill="black"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>

                    <input
                      ref={imageRef}
                      style={{ display: "none" }}
                      type="file"
                      id="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                  </Col>
                  <Col md={8}>
                    <FormInput
                      error={errors.title}
                      id="title"
                      key={"title"}
                      label="Goal Title"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter email"
                      touched={touched.title}
                      type="text"
                      value={values.title || ""}
                    />

                    <FormInput
                      error={errors.amount}
                      id="amount"
                      key={"amount"}
                      label="Goal Amount"
                      name="amount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Goal Amount"
                      touched={touched.amount}
                      type="number"
                      value={values.amount || ""}
                      className=""
                    />
                  </Col>
                </Row>
              </FormikForm>
            </ModalBody>
            <ModalFooter className="border-0 pt-0">
              <Button
                className="fs-14 fw-semibold mw-44"
                variant="outline-dark"
                onClick={toggle}
              >
                Cancel
              </Button>{" "}
              <Button
                className="fs-14 fw-semibold mw-44"
                variant="primary"
                onClick={handleSubmit}
              >
                {isEditMode ? "Update" : "Submit"}
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default GoalModal;

import { GET_USER_CHALLENGE_BY_ID } from "../constants/api-const";
import instance from "../utils/instance";

export const handleGetUsers = async (params) => {
  return await instance.get("/v1/users", {
    params,
  });
};

export const handleGetUser = async (id) => {
  return await instance.get(`/v1/users/${id}`);
};

export const handleUpdateUser = async (id, data) => {
  return await instance.patch(`/v1/users/${id}`, data);
};

export const handleDeleteUser = async (id) => {
  return await instance.delete(`/v1/users/${id}`);
};

/**
 * Fetches user challenges by user ID.
 *
 * This function sends a GET request to retrieve challenges for a specific user by their ID.
 *
 * @async
 * @function getUserChallengesById
 */
export const getUserChallengesById = async (userId) => {
  const result = await instance.get(`${GET_USER_CHALLENGE_BY_ID}${userId}`);
  return result.data;
};

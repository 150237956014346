import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

import FormInput from "../../components/FormInput";
import { validationSchema } from "../../validations/profile.validation";
import { HiMiniUsers } from "react-icons/hi2";
import "./ChangePassword.scss";
import { Button, Card, CardFooter, Col, Stack } from "react-bootstrap";
import {
  handleGetProfile,
  handleUpdateProfile,
} from "../../services/profile.service";
import { useNavigate } from "react-router-dom";
import SvgIcons from "../../components/SVGIcons";
import { AuthenticationContext } from "../../contexts/authentication.context";
import Loading from "../../components/Loading";

export default function Profile() {
  const [loader, setLoader] = useState(true);
  const [profile, setProfile] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const imageRef = React.useRef(null);
  const { updateUser } = useContext(AuthenticationContext);

  const navigate = useNavigate();

  useEffect(() => {
    handleGetProfile().then((response) => {
      setProfile(response.data.data);
      setLoader(false);
    });
  }, []);

  const onSubmit = async (values, actions) => {
    const formData = new FormData();

    if (uploadedImage) {
      formData.append("profileImage", uploadedImage);
    }
    formData.append("name", values.name);

    await handleUpdateProfile(formData)
      .then((response) => {
        toast.success(response.data.message);
        actions.resetForm();
        navigate("/");
        updateUser(response.data.data);
      })
      .catch((error) => {
        actions.resetForm();
        toast.error(error.response.data.message);
      });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    // file size not grater than 1MB

    if (!file) return;

    if (file && file.size >= 1 * 1024 * 1024) {
      toast("Image should be allowed up to 1 MB");
      event.target.value = null;
      return;
    }

    setUploadedImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      {loader ? (
          <Loading isLoading={loader} />
      ) : (
        <>
          <div className="contentHeader p-1">
            <Stack
              direction="horizontal"
              gap={2}
              className="flex-wrap justify-content-between custom-min-height-42"
            >
              <h1 className="fw-semibold h4 mb-0 fs-22">Profile</h1>
            </Stack>
          </div>
          <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
            <Formik
              initialValues={{
                name: profile && profile.name,
                email: profile && profile.email,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
                  <div className="theme-card-header px-1">
                    <Stack
                      gap={3}
                      className="flex-wrap px-3 pt-3 pb-2 align-items-start"
                    >
                      <h5 className="mb-0 position-relative fw-semibold fs-16">
                        <div className="align-items-center bg-info d-inline-flex custom-height-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box custom-width-60 z-1">
                          <HiMiniUsers size={32} />
                        </div>
                        Profile
                      </h5>
                    </Stack>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column px-3 pb-1 overflow-auto">
                    <div className="p-1 ">
                      <div className="row">
                        <Col className=" col-auto">
                          <div
                            className="goal-image mb-3 position-relative"
                            style={{
                              width: "120px",
                            }}
                            onClick={() => {
                              imageRef.current.click();
                            }}
                          >
                            {imagePreview || profile?.profileImage ? (
                              <img
                                src={imagePreview || profile?.profileImage}
                                alt="profile"
                              />
                            ) : (
                              SvgIcons.imagePreview
                            )}
                            <div
                              className="edit-icon"
                              // onClick={() => {
                              //   imageRef.current.click();
                              // }}
                            >
                              <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="15" cy="15" r="15" fill="#FFC73B" />
                                <mask
                                  id="mask0_2037_3252"
                                  maskUnits="userSpaceOnUse"
                                  x="6"
                                  y="6"
                                  width="18"
                                  height="18"
                                >
                                  <rect
                                    x="6"
                                    y="6"
                                    width="18"
                                    height="18"
                                    fill="#D9D9D9"
                                  />
                                </mask>
                                <g mask="url(#mask0_2037_3252)">
                                  <path
                                    d="M8.25 21.75V18.5625L18.15 8.68125C18.3 8.54375 18.4656 8.4375 18.6469 8.3625C18.8281 8.2875 19.0188 8.25 19.2188 8.25C19.4187 8.25 19.6125 8.2875 19.8 8.3625C19.9875 8.4375 20.15 8.55 20.2875 8.7L21.3188 9.75C21.4688 9.8875 21.5781 10.05 21.6469 10.2375C21.7156 10.425 21.75 10.6125 21.75 10.8C21.75 11 21.7156 11.1906 21.6469 11.3719C21.5781 11.5531 21.4688 11.7188 21.3188 11.8688L11.4375 21.75H8.25ZM19.2 11.85L20.25 10.8L19.2 9.75L18.15 10.8L19.2 11.85Z"
                                    fill="black"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>

                          <input
                            ref={imageRef}
                            style={{ display: "none" }}
                            type="file"
                            id="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                          />
                        </Col>
                        <div className="col-md-4 mt-2">
                          <div className="content px-2">
                            <FormInput
                              error={errors.name}
                              id="name"
                              key={"name"}
                              label="Name"
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter New password"
                              touched={touched.name}
                              type="text"
                              value={values.name}
                            />
                            <FormInput
                              disabled
                              error={errors.email}
                              id="email"
                              key={"email"}
                              label="Email"
                              name="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Enter New password"
                              touched={touched.email}
                              type="text"
                              value={values.email}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <CardFooter className="">
                    <div className="d-flex justify-content-end">
                      <Button
                        className="fs-14 fw-semibold"
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              )}
            </Formik>
          </div>
        </>
      )}
    </div>
  );
}

import React from "react";
import { Button, Stack } from "react-bootstrap";

const Header = ({ toggle }) => {
  return (
    <div className="contentHeader p-1">
      <Stack
        direction="horizontal"
        gap={2}
        className="flex-wrap justify-content-between custom-min-height-42"
      >
        <h1 className="fw-semibold h4 mb-0 fs-22  pb-3 pt-1">Goals</h1>
        <Button
          color="primary"
          className="fw-semibold mw-44 btn btn-primary btn-sm align-items-center d-flex"
          onClick={toggle}
        >
          Add New
        </Button>
      </Stack>
    </div>
  );
};

export default Header;

import React, { useContext } from "react";
import {
  Badge,
  Button,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
} from "react-bootstrap";
import {
  FaBell,
  FaCaretDown,
  FaLock,
  FaSignOutAlt,
  FaUserCircle,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../assets/images/logo.png";
import { AuthenticationContext } from "../../contexts/authentication.context";
import "./header.scss";

export default function Header({ isActiveSidebar, toggleSidebarButton }) {
  const { logout, user } = useContext(AuthenticationContext);

  const imageUrl = localStorage.getItem("imageUrl");
  const navigate = useNavigate();

  return (
    <>
      <Navbar
        bg="white"
        data-bs-theme="light"
        variant="light"
        className="py-0 px-md-1 shadow-sm z-2 theme-top-header"
      >
        <Container fluid className="h-100">
          <Button
            onClick={toggleSidebarButton}
            variant="link"
            className="align-items-center d-flex d-xl-none justify-content-center me-3 navMenuBtn p-0 py-3"
          >
            <span
              className={`bg-light d-inline-block menuTrigger position-relative text-center ${
                isActiveSidebar ? "active" : ""
              }`}
            ></span>
          </Button>
          <Link to="/" className="me-3">
            <Image
              className="img-fluid"
              src={Logo}
              alt={`Logo`}
              width={42}
              height={40}
            />
          </Link>
          <span className="text-decoration-none text-black fs-24 fw-bolder">
            Pouch
          </span>
          <Nav className="ms-auto align-items-center order-md-last">
            <Dropdown className="profileDropdown ms-4">
              <Dropdown.Toggle
                variant="link"
                id="dropdown-profile"
                className="border-0 fw-semibold text-decoration-none p-0 text-body"
              >
                <Image
                  className="object-fit-cover rounded-circle"
                  src={
                    user.profileImage
                      ? user.profileImage
                      : "https://dummyimage.com/40"
                  }
                  alt={`${user.name} Profile Image`}
                  width={40}
                  height={40}
                />
                <span className="align-middle d-none d-md-inline-block ms-1 px-2 text-truncate custom-max-width-150">
                  {user.name}
                </span>
                <FaCaretDown size={16} />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end" className="shadow-sm">
                <Dropdown.Item
                  onClick={() => {
                    navigate("/profile");
                  }}
                  className="fw-medium gap-2 d-flex align-items-center"
                >
                  <FaUserCircle />
                  Profile
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate("/change-password");
                  }}
                  className="fw-medium gap-2 d-flex align-items-center"
                >
                  <FaLock />
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={logout}
                  className="fw-medium gap-2 d-flex align-items-center"
                >
                  <FaSignOutAlt />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

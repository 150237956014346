import instance from "../utils/instance";

export const handleChangePassword = async (data) => {
  return await instance.post("/v1/auth/change-password", data);
};

export const handleForgotPassword = async (data) => {
  return await instance.post("/v1/auth/forgot-password", data);
};

export const handleLogin = async (data) => {
  return await instance.post("/v1/auth/login", data);
};

export const handleLogout = async () => {
  return await instance.post("/v1/auth/logout");
};

export const handleResetPassword = async (password, token) => {
  return await instance.post(
    "/v1/auth/reset-password",
    { password },
    {
      params: {
        token,
      },
    }
  );
};

export const handleRefreshToken = async () => {
  return await instance.post("/v1/auth/refresh-tokens", {
    refreshToken: JSON.parse(localStorage.getItem("refresh_token")),
  });
};

import {
  GET_CMS_BY_ID,
  GET_CMS_LIST,
  UPDATE_CMS,
} from "../constants/api-const";
import instance from "../utils/instance";

/**
 * Fetches a list of CMS entries with optional query parameters.
 *
 * This function sends a GET request to retrieve a list of CMS entries based on the provided query parameters.
 *
 * @async
 * @function getCMSList
 *
 * @param {Object} params - An object containing query parameters to filter the CMS list.
 * @returns {Promise<Object>} A promise that resolves to the data of the CMS list.
 */
export const getCMSList = async (params) => {
  const result = await instance.get(GET_CMS_LIST, { params });
  return result.data;
};

/**
 * Fetches CMS data by its ID.
 *
 * This function sends a GET request to retrieve data for a specific CMS entry by its ID.
 *
 * @async
 * @function getCMSDataById
 *
 * @param {string} strId - The ID of the CMS entry to fetch.
 * @returns {Promise<Object>} A promise that resolves to the data of the CMS entry.
 */
export const getCMSDataById = async (strId) => {
  const objData = await instance.get(`${GET_CMS_BY_ID}${strId}`);
  return objData.data;
};

/**
 * Updates a CMS entry by its ID.
 *
 * This function sends a PUT request to update a specific CMS entry by its ID with the provided data.
 *
 * @async
 * @function UpdateCMSById
 *
 * @param {string} id - The ID of the CMS entry to update.
 * @param {Object} data - The data to update the CMS entry with.
 * @returns {Promise<Object>} A promise that resolves to the updated data of the CMS entry.
 */
export const UpdateCMSById = async (id, data) => {
  return await instance.put(`${UPDATE_CMS}${id}`, data);
};

export const getWebsiteCMSPages = async () => {
  const result = await instance.get(`${GET_CMS_LIST}/for-website`);
  return result.data;
};

import { Spinner } from "react-bootstrap";

const Loading = ({ isLoading, classCustom }) => {
  return (
    <div
      className={`z-999 align-items-center bg-black bg-opacity-50 d-flex h-100 justify-content-center position-fixed start-0 top-0 w-100 z-3 ${
        isLoading === true ? "d-block" : "d-none"
      } ${classCustom}`}
    >
      <Spinner animation="border" variant="light" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loading;

import { useContext } from "react";
import Routers from "./routes/Routers";
import { AuthenticationContext } from "./contexts/authentication.context";

function App() {
  const { isLoading } = useContext(AuthenticationContext);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Routers />;
}

export default App;

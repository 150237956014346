/**
 * ALL available SVG icons which can be use in application
 */
const SvgIcons = {
  eyeIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10.284"
      viewBox="0 0 16 10.284"
    >
      <path
        d="M-6254.372-464.906a.569.569,0,0,1,0-.762c2.711-2.954,4.97-4.76,7.837-4.76,3.422,0,6.009,2.492,7.884,4.76a.557.557,0,0,1-.016.739c-1.835,2.01-4.43,4.784-7.868,4.784C-6250.012-460.143-6252.059-462.346-6254.372-464.906Zm7.7-3.589a3.226,3.226,0,0,0-3.062,3.06,3.217,3.217,0,0,0,.93,2.422,3.254,3.254,0,0,0,2.43.938,3.227,3.227,0,0,0,3.063-3.06,3.216,3.216,0,0,0-.891-2.369,3.205,3.205,0,0,0-2.329-.995C-6246.574-468.5-6246.621-468.5-6246.668-468.5Zm-.031,5.488a2.293,2.293,0,0,1-2.1-2.1,2.278,2.278,0,0,1,.609-1.727,2.267,2.267,0,0,1,1.672-.734c.039,0,.07,0,.109,0a1.589,1.589,0,0,0-.109.6,1.655,1.655,0,0,0,1.633,1.677,1.592,1.592,0,0,0,.648-.14v.14A2.286,2.286,0,0,1-6246.52-463a1.472,1.472,0,0,1-.18,0Z"
        transform="translate(6254.519 470.429)"
        fill="currentColor"
      />
    </svg>
  ),

  eyeOffIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.003"
      height="13.72"
      viewBox="0 0 16.003 13.72"
    >
      <path
        d="M-6278.344-456.854l-12.7-12.708a.494.494,0,0,1,0-.714.518.518,0,0,1,.727,0l12.7,12.708a.51.51,0,0,1,0,.715.489.489,0,0,1-.234.132.408.408,0,0,1-.133.018A.494.494,0,0,1-6278.344-456.854Zm-13.852-6.334a.545.545,0,0,1,.023-.761,22.813,22.813,0,0,1,3.031-2.906l1.9,1.912a2.8,2.8,0,0,0-.3,1.225,3.132,3.132,0,0,0,.93,2.424,3.24,3.24,0,0,0,2.43.938,3.178,3.178,0,0,0,1.219-.305l1.563,1.555a6.885,6.885,0,0,1-2.937.686C-6287.828-458.422-6289.883-460.626-6292.2-463.188Zm10.758,1a3.349,3.349,0,0,0,.32-1.227,3.264,3.264,0,0,0-.953-2.422,3.208,3.208,0,0,0-2.43-.939,3.158,3.158,0,0,0-1.211.305l-1.57-1.561a6.764,6.764,0,0,1,2.945-.679c3.406,0,6.008,2.493,7.867,4.762a.556.556,0,0,1-.016.739,23.867,23.867,0,0,1-3.039,2.93Zm-3.062.9a2.276,2.276,0,0,1-2.109-2.1,2.034,2.034,0,0,1,.078-.839l2.844,2.851a2.119,2.119,0,0,1-.641.1C-6284.391-461.28-6284.461-461.283-6284.5-461.287Zm1.68-2.283c.039,0,.078,0,.109,0a1.652,1.652,0,0,0,.672-.14v.14a2.152,2.152,0,0,1-.109.665Zm-2.18-2.186a2.2,2.2,0,0,1,.664-.1l.117,0a1.778,1.778,0,0,0-.117.6c0,.05.008.107.008.161Z"
        transform="translate(6292.339 470.426)"
        fill="currentColor"
      />
    </svg>
  ),

  infoCircleIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        id="information"
        d="M9.7,6.9H8.3V5.5H9.7m0,7H8.3V8.3H9.7M9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2Z"
        transform="translate(-1.999 -1.998)"
        fill="currentColor"
      />
    </svg>
  ),

  supplierIcons: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.033"
      height="17"
      viewBox="0 0 22.033 17"
    >
      <path
        fill="currentColor"
        d="M13.428 16.999a.651.651 0 0 1-.65-.645V8.389a.651.651 0 0 1 .65-.645h2.014v2.555a.647.647 0 0 0 .932.578l1.035-.524.986.493a.627.627 0 0 0 .34.1.646.646 0 0 0 .65-.645V7.742h2a.648.648 0 0 1 .645.645v7.965a.648.648 0 0 1-.645.645Zm-12.783 0A.643.643 0 0 1 0 16.354v-2.012a4.22 4.22 0 0 1 3.205-4.123l2.232-.574a.632.632 0 0 1 .68.255l1.422 1.869L8.955 9.9a.636.636 0 0 1 .668-.233l1.863.466v6.226a1.9 1.9 0 0 0 .113.641ZM17.7 9.066a.64.64 0 0 0-.578 0l-.391.193V7.744h1.363v1.515ZM3.192 4.351A4.348 4.348 0 1 1 7.54 8.699a4.346 4.346 0 0 1-4.349-4.352Z"
      />
    </svg>
  ),

  customerIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.232"
      height="20.998"
      viewBox="0 0 16.232 20.998"
    >
      <path
        fill="currentColor"
        d="M3.436 20.699c-1.792-.332-3.961-1.054-3.321-3.424.291-1.07 1.583-1.854 2.76-2.5a16.859 16.859 0 0 1 1.734-.869c.57-.23 1.362-.435 1.432-1.177.062-.623-.336-.96-.714-1.481a7.4 7.4 0 0 1-1.533-5.007 4.135 4.135 0 0 1 3.728-4.085c3.088-.353 5.241 1.7 4.954 5.213a7.766 7.766 0 0 1-.742 2.571 5.729 5.729 0 0 1-.923.2 1.256 1.256 0 0 0-.779-.271h-.869a1.259 1.259 0 0 0 0 2.518h.869a1.621 1.621 0 0 0 .246-.025 1.388 1.388 0 0 0 0 .673c.173.66 1.62 1.05 2.146 1.28 1.337.586 3.207 1.46 3.679 2.859a3.216 3.216 0 0 1 0 1.788c-.751 1.94-5.389 2.042-7.969 2.042a26.91 26.91 0 0 1-4.698-.305Zm5.726-8.916a.656.656 0 1 1 0-1.312h.869a.658.658 0 0 1 .55.3 5.385 5.385 0 0 0 2.612-.869 1.126 1.126 0 0 1-.078-.406V7.31a1.092 1.092 0 0 1 .607-.98 5.614 5.614 0 0 0-11.213 0 1.1 1.1 0 0 1 .611.98v2.186a1.1 1.1 0 0 1-1.095 1.095h-.054A1.1 1.1 0 0 1 .876 9.496V7.31a1.091 1.091 0 0 1 .623-.988 6.622 6.622 0 0 1 13.23 0 1.091 1.091 0 0 1 .623.988v2.186a1.1 1.1 0 0 1-1.095 1.095h-.054a1.061 1.061 0 0 1-.586-.176 5.862 5.862 0 0 1-3.011 1.013.649.649 0 0 1-.578.357Z"
      />
    </svg>
  ),

  catalogIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.118"
      height="22.002"
      viewBox="0 0 18.118 22.002"
    >
      <path
        fill="currentColor"
        d="M5.178 22.002V3.883h12.94v18.119Zm2.589-2.589h7.763v-1.294H7.767Zm0-2.589h7.763V15.53H7.767Zm0-2.589h7.763V6.472H7.767ZM0 19.412V.002h8.2L3.885 2.591v16.821Zm9.057-6.472v-.938l1.405-.916 1.278.526 1.282-.953 1.212.715v1.57Zm0-5.174h5.178v2.1l-1.29-.764-1.389 1.031-1.229-.51-1.27.83ZM6.041 2.588l4.311-2.589v2.589Z"
      />
    </svg>
  ),

  productIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.098"
      height="20"
      viewBox="0 0 20.098 20"
    >
      <path
        fill="currentColor"
        d="M8.352 9.1a4.784 4.784 0 0 0 .86.268V20a2.947 2.947 0 0 1-.525-.19l-6.7-2.981A3.349 3.349 0 0 1 0 13.77V6.334a3.3 3.3 0 0 1 .123-.882ZM13.2 6.613 4.1 2.336l-2.11.938a3.248 3.248 0 0 0-1.016.7l8.05 3.584a2.532 2.532 0 0 0 2.054 0Zm5.929-2.635a3.249 3.249 0 0 0-1.016-.7L11.411.293a3.312 3.312 0 0 0-2.724 0L6.13 1.432l9.1 4.276Zm.848 1.474-3.921 1.741v2.736a.837.837 0 0 1-1.675 0v-2L11.746 9.1a5.894 5.894 0 0 1-.86.268V20a2.944 2.944 0 0 0 .525-.19l6.7-2.981A3.349 3.349 0 0 0 20.1 13.77V6.334a3.3 3.3 0 0 0-.125-.882Z"
      />
    </svg>
  ),

  userManagementIcon: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.6667 7.33325C11.7734 7.33325 12.6601 6.43992 12.6601 5.33325C12.6601 4.22659 11.7734 3.33325 10.6667 3.33325C9.56008 3.33325 8.66675 4.22659 8.66675 5.33325C8.66675 6.43992 9.56008 7.33325 10.6667 7.33325ZM5.33341 7.33325C6.44008 7.33325 7.32675 6.43992 7.32675 5.33325C7.32675 4.22659 6.44008 3.33325 5.33341 3.33325C4.22675 3.33325 3.33341 4.22659 3.33341 5.33325C3.33341 6.43992 4.22675 7.33325 5.33341 7.33325ZM5.33341 8.66659C3.78008 8.66659 0.666748 9.44659 0.666748 10.9999V11.9999C0.666748 12.3666 0.966748 12.6666 1.33341 12.6666H9.33341C9.70008 12.6666 10.0001 12.3666 10.0001 11.9999V10.9999C10.0001 9.44659 6.88675 8.66659 5.33341 8.66659ZM10.6667 8.66659C10.4734 8.66659 10.2534 8.67992 10.0201 8.69992C10.0334 8.70659 10.0401 8.71992 10.0467 8.72659C10.8067 9.27992 11.3334 10.0199 11.3334 10.9999V11.9999C11.3334 12.2333 11.2867 12.4599 11.2134 12.6666H14.6667C15.0334 12.6666 15.3334 12.3666 15.3334 11.9999V10.9999C15.3334 9.44659 12.2201 8.66659 10.6667 8.66659Z" />
    </svg>
  ),
  manageTemplate: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.40337 12.3566C8.16337 12.5432 7.82337 12.5432 7.58337 12.3566L3.48337 9.16991C3.24337 8.98325 2.91004 8.98325 2.67004 9.16991C2.33004 9.43658 2.33004 9.94991 2.67004 10.2166L7.17671 13.7232C7.65671 14.0966 8.33004 14.0966 8.81671 13.7232L13.3234 10.2166C13.6634 9.94991 13.6634 9.43658 13.3234 9.16991L13.3167 9.16325C13.0767 8.97658 12.7434 8.97658 12.5034 9.16325L8.40337 12.3566ZM8.82337 10.3432L13.33 6.83658C13.67 6.56991 13.67 6.04991 13.33 5.78325L8.82337 2.27658C8.34337 1.90325 7.67004 1.90325 7.18337 2.27658L2.67671 5.78992C2.33671 6.05658 2.33671 6.57658 2.67671 6.84325L7.18337 10.3499C7.66337 10.7232 8.34337 10.7232 8.82337 10.3432Z" />
    </svg>
  ),
  llmConnection: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.3333 3.33333V2.66667C13.3333 2.3 13.0333 2 12.6667 2H11.3333C10.9667 2 10.6667 2.3 10.6667 2.66667V3.33333C10.3 3.33333 10 3.63333 10 4V6C10 6.36667 10.3 6.66667 10.6667 6.66667H11.3333V11.2733C11.3333 11.9667 10.8267 12.5933 10.14 12.66C9.34 12.74 8.66667 12.1133 8.66667 11.3333V4.76C8.66667 3.34 7.59333 2.1 6.17333 2.00667C4.62 1.90667 3.33333 3.13333 3.33333 4.66667V9.33333H2.66667C2.3 9.33333 2 9.63333 2 10V12C2 12.3667 2.3 12.6667 2.66667 12.6667V13.3333C2.66667 13.7 2.96667 14 3.33333 14H4.66667C5.03333 14 5.33333 13.7 5.33333 13.3333V12.6667C5.7 12.6667 6 12.3667 6 12V10C6 9.63333 5.7 9.33333 5.33333 9.33333H4.66667V4.72667C4.66667 4.03333 5.17333 3.40667 5.86 3.34C6.66 3.26 7.33333 3.88667 7.33333 4.66667V11.24C7.33333 12.66 8.40667 13.9 9.82667 13.9933C11.38 14.0933 12.6667 12.8667 12.6667 11.3333V6.66667H13.3333C13.7 6.66667 14 6.36667 14 6V4C14 3.63333 13.7 3.33333 13.3333 3.33333Z" />
    </svg>
  ),
  goalsIcon: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M12.2047 3.77816C11.9647 4.01816 11.9447 4.39149 12.1581 4.65816C13.1247 5.87149 13.6314 7.53149 13.1781 9.27149C12.6514 11.3048 11.0581 12.8248 9.03805 13.2315C5.63805 13.9048 2.65805 11.2782 2.65805 7.99149C2.65805 5.27149 4.69139 3.03149 7.32472 2.70482V4.05149C5.23805 4.40482 3.70472 6.35816 4.03805 8.60482C4.29805 10.3448 5.74472 11.7448 7.49139 11.9582C9.91806 12.2582 11.9914 10.3648 11.9914 7.99149C11.9914 7.15816 11.7381 6.39149 11.3047 5.75149C11.0781 5.41816 10.5914 5.39816 10.3047 5.67816L10.2981 5.68482C10.0714 5.91149 10.0514 6.26482 10.2247 6.53149C10.6247 7.14482 10.7847 7.93149 10.5514 8.74482C10.2914 9.65816 9.52472 10.3848 8.59139 10.5915C6.85806 10.9715 5.32472 9.66482 5.32472 7.99149C5.32472 6.75149 6.17806 5.71816 7.32472 5.41816V6.84482C6.92472 7.07816 6.65805 7.49816 6.65805 7.99149C6.65805 8.72482 7.25806 9.32482 7.99139 9.32482C8.72472 9.32482 9.32472 8.72482 9.32472 7.99149C9.32472 7.49816 9.05806 7.07149 8.65806 6.84482V1.79816C8.65806 1.53816 8.44472 1.32482 8.18472 1.32482C4.61139 1.19149 1.53139 4.03149 1.33805 7.59149C1.09805 11.9582 4.94472 15.4715 9.34472 14.5248C11.9314 13.9715 13.9314 11.9915 14.5114 9.41149C14.9847 7.30482 14.3781 5.30482 13.1981 3.83149C12.9514 3.51816 12.4847 3.49816 12.2047 3.77816Z"
      />
    </svg>
  ),
  settings: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 7.99992C13 7.84659 12.9934 7.69992 12.98 7.54659L14.22 6.60659C14.4867 6.40659 14.56 6.03325 14.3934 5.73992L13.1467 3.58659C12.98 3.29325 12.62 3.17325 12.3134 3.30659L10.88 3.91325C10.6334 3.73992 10.3734 3.58659 10.1 3.45992L9.90669 1.91992C9.86669 1.58659 9.58002 1.33325 9.24669 1.33325H6.76002C6.42002 1.33325 6.13335 1.58659 6.09335 1.91992L5.90002 3.45992C5.62669 3.58659 5.36669 3.73992 5.12002 3.91325L3.68669 3.30659C3.38002 3.17325 3.02002 3.29325 2.85335 3.58659L1.60669 5.74659C1.44002 6.03992 1.51335 6.40659 1.78002 6.61325L3.02002 7.55325C3.00669 7.69992 3.00002 7.84659 3.00002 7.99992C3.00002 8.15325 3.00669 8.29992 3.02002 8.45325L1.78002 9.39325C1.51335 9.59325 1.44002 9.96659 1.60669 10.2599L2.85335 12.4133C3.02002 12.7066 3.38002 12.8266 3.68669 12.6933L5.12002 12.0866C5.36669 12.2599 5.62669 12.4133 5.90002 12.5399L6.09335 14.0799C6.13335 14.4133 6.42002 14.6666 6.75335 14.6666H9.24002C9.57335 14.6666 9.86002 14.4133 9.90002 14.0799L10.0934 12.5399C10.3667 12.4133 10.6267 12.2599 10.8734 12.0866L12.3067 12.6933C12.6134 12.8266 12.9734 12.7066 13.14 12.4133L14.3867 10.2599C14.5534 9.96659 14.48 9.59992 14.2134 9.39325L12.9734 8.45325C12.9934 8.29992 13 8.15325 13 7.99992ZM8.02669 10.3333C6.74002 10.3333 5.69335 9.28659 5.69335 7.99992C5.69335 6.71325 6.74002 5.66659 8.02669 5.66659C9.31335 5.66659 10.36 6.71325 10.36 7.99992C10.36 9.28659 9.31335 10.3333 8.02669 10.3333Z" />
    </svg>
  ),
  cms: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.44667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V6.55333C14 6.2 13.86 5.86 13.6067 5.61333L10.3867 2.39333C10.14 2.14 9.8 2 9.44667 2ZM5.33333 10H10.6667C11.0333 10 11.3333 10.3 11.3333 10.6667C11.3333 11.0333 11.0333 11.3333 10.6667 11.3333H5.33333C4.96667 11.3333 4.66667 11.0333 4.66667 10.6667C4.66667 10.3 4.96667 10 5.33333 10ZM5.33333 7.33333H10.6667C11.0333 7.33333 11.3333 7.63333 11.3333 8C11.3333 8.36667 11.0333 8.66667 10.6667 8.66667H5.33333C4.96667 8.66667 4.66667 8.36667 4.66667 8C4.66667 7.63333 4.96667 7.33333 5.33333 7.33333ZM5.33333 4.66667H8.66667C9.03333 4.66667 9.33333 4.96667 9.33333 5.33333C9.33333 5.7 9.03333 6 8.66667 6H5.33333C4.96667 6 4.66667 5.7 4.66667 5.33333C4.66667 4.96667 4.96667 4.66667 5.33333 4.66667Z" />
    </svg>
  ),
  savingChallenges: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.2199 5.33327L11.7066 3.81994C11.7533 3.53994 11.8266 3.27994 11.9199 3.05327C11.9933 2.87994 12.0199 2.67994 11.9799 2.47327C11.8933 1.99327 11.4266 1.65994 10.9333 1.6666C9.87325 1.6866 8.93325 2.2066 8.33325 2.99994H4.99992C2.97325 2.99994 1.33325 4.63994 1.33325 6.6666C1.33325 8.1666 2.24659 11.6533 2.71992 13.3599C2.87992 13.9333 3.40659 14.3333 4.00659 14.3333H5.33325C6.06659 14.3333 6.66659 13.7333 6.66659 12.9999H7.99992C7.99992 13.7333 8.59992 14.3333 9.33325 14.3333H10.6733C11.2599 14.3333 11.7799 13.9466 11.9533 13.3799L12.7866 10.6066L14.2133 10.1266C14.4866 10.0333 14.6666 9.77993 14.6666 9.49327V5.99994C14.6666 5.63327 14.3666 5.33327 13.9999 5.33327H13.2199ZM7.99992 6.33327H5.99992C5.63325 6.33327 5.33325 6.03327 5.33325 5.6666C5.33325 5.29994 5.63325 4.99994 5.99992 4.99994H7.99992C8.36659 4.99994 8.66659 5.29994 8.66659 5.6666C8.66659 6.03327 8.36659 6.33327 7.99992 6.33327ZM10.6666 7.6666C10.2999 7.6666 9.99992 7.3666 9.99992 6.99994C9.99992 6.63327 10.2999 6.33327 10.6666 6.33327C11.0333 6.33327 11.3333 6.63327 11.3333 6.99994C11.3333 7.3666 11.0333 7.6666 10.6666 7.6666Z" />
    </svg>
  ),
  reactSelectArrow: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.77083 7.49998L10.0042 10.7333L13.2375 7.49998C13.5625 7.17498 14.0875 7.17498 14.4125 7.49998C14.7375 7.82498 14.7375 8.34998 14.4125 8.67498L10.5875 12.5C10.2625 12.825 9.7375 12.825 9.4125 12.5L5.5875 8.67498C5.2625 8.34998 5.2625 7.82498 5.5875 7.49998C5.9125 7.18331 6.44583 7.17498 6.77083 7.49998Z"
        fill="black"
      />
    </svg>
  ),

  calendarIcon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 3.33335H15V2.50002C15 2.04169 14.625 1.66669 14.1667 1.66669C13.7083 1.66669 13.3333 2.04169 13.3333 2.50002V3.33335H6.66667V2.50002C6.66667 2.04169 6.29167 1.66669 5.83333 1.66669C5.375 1.66669 5 2.04169 5 2.50002V3.33335H4.16667C3.24167 3.33335 2.50833 4.08335 2.50833 5.00002L2.5 16.6667C2.5 17.5834 3.24167 18.3334 4.16667 18.3334H15.8333C16.75 18.3334 17.5 17.5834 17.5 16.6667V5.00002C17.5 4.08335 16.75 3.33335 15.8333 3.33335ZM15.8333 15.8334C15.8333 16.2917 15.4583 16.6667 15 16.6667H5C4.54167 16.6667 4.16667 16.2917 4.16667 15.8334V7.50002H15.8333V15.8334ZM5.83333 9.16669H7.5V10.8334H5.83333V9.16669ZM9.16667 9.16669H10.8333V10.8334H9.16667V9.16669ZM12.5 9.16669H14.1667V10.8334H12.5V9.16669Z"
        fill="black"
      />
    </svg>
  ),
  orderIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="18.429"
      viewBox="0 0 15 18.429"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15 1.5v16.286a.643.643 0 0 1-1.005.531l-2.139-1.46-2.022 1.451a.641.641 0 0 1-.8-.039L7.5 16.926l-1.536 1.343a.641.641 0 0 1-.8.039l-2.02-1.451-2.139 1.46A.643.643 0 0 1 0 17.786V1.5A1.5 1.5 0 0 1 1.5 0h12A1.5 1.5 0 0 1 15 1.5ZM4.071 8.143h6.857a.643.643 0 0 0 0-1.286H4.071a.643.643 0 1 0 0 1.286Zm0-3.429h6.857a.643.643 0 0 0 0-1.286H4.071a.643.643 0 1 0 0 1.286Zm0 6.857H7.5a.643.643 0 1 0 0-1.286H4.071a.643.643 0 1 0 0 1.286Z"
      />
    </svg>
  ),

  quotationIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.5"
      height="17.998"
      viewBox="0 0 13.5 17.998"
    >
      <path
        fill="currentColor"
        d="M1.124 17.999A1.126 1.126 0 0 1 0 16.875V1.123A1.126 1.126 0 0 1 1.124-.001h6.749v4.5a1.129 1.129 0 0 0 1.124 1.128h4.5v11.248a1.126 1.126 0 0 1-1.124 1.124Zm1.123-2.813a.568.568 0 0 0 .566.566h7.873a.564.564 0 0 0 0-1.128H2.813a.567.567 0 0 0-.566.56Zm0-2.247a.564.564 0 0 0 .566.562h7.873a.562.562 0 0 0 0-1.124H2.813a.564.564 0 0 0-.566.56Zm5.626-2.251a.563.563 0 0 0 .563.562h2.251a.562.562 0 0 0 0-1.124H8.436a.563.563 0 0 0-.563.56Zm-4.97-1.874a.561.561 0 0 0 .082.791 2.323 2.323 0 0 0 .951.451v.632a.562.562 0 0 0 1.124 0v-.632a2.039 2.039 0 0 0 1.689-1.9 2.125 2.125 0 0 0-2.251-1.968c-.607 0-1.124-.385-1.124-.845s.517-.845 1.124-.845a1.275 1.275 0 0 1 .8.267.561.561 0 1 0 .709-.869 2.322 2.322 0 0 0-.951-.451v-.631a.562.562 0 0 0-1.124 0v.631a2.039 2.039 0 0 0-1.689 1.9 2.125 2.125 0 0 0 2.252 1.968c.61 0 1.123.385 1.123.845s-.513.845-1.123.845a1.263 1.263 0 0 1-.8-.271.568.568 0 0 0-.355-.125.558.558 0 0 0-.441.205Zm4.97-.377a.567.567 0 0 0 .563.566h2.251a.564.564 0 0 0 0-1.128H8.436a.566.566 0 0 0-.563.56Zm1.126-3.938v-4.5l4.5 4.5Z"
      />
    </svg>
  ),

  invoiceIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.8"
      height="18"
      viewBox="0 0 13.8 18"
    >
      <path
        fill="currentColor"
        d="M13.49 4.5c-.274.008-.872 0-2.39 0a1.8 1.8 0 0 1-1.8-1.8c0-1.518-.006-2.117 0-2.39A.3.3 0 0 0 9 0H1.2A1.2 1.2 0 0 0 0 1.2v15.6A1.2 1.2 0 0 0 1.2 18h11.4a1.2 1.2 0 0 0 1.2-1.2v-12a.3.3 0 0 0-.31-.3ZM2.475 3.525h2.662a.3.3 0 0 1 0 .6H2.475a.3.3 0 0 1 0-.6Zm0 1.875h4.162a.3.3 0 0 1 0 .6H2.475a.3.3 0 0 1 0-.6Zm9.15 8.85a.3.3 0 0 1-.3.3h-8.85a.3.3 0 0 1-.3-.3V8.1a.3.3 0 0 1 .3-.3h8.85a.3.3 0 0 1 .3.3ZM8.25 11.912H3.075a.3.3 0 0 1-.3-.3v-.873a.3.3 0 0 1 .3-.3H8.25a.3.3 0 0 1 .3.3v.873a.3.3 0 0 1-.3.3ZM9.45 8.4h1.275a.3.3 0 0 1 .3.3v.838a.3.3 0 0 1-.3.3H9.45a.3.3 0 0 1-.3-.3V8.7a.3.3 0 0 1 .3-.3ZM2.775 9.539V8.7a.3.3 0 0 1 .3-.3H8.25a.3.3 0 0 1 .3.3v.838a.3.3 0 0 1-.3.3H3.075a.3.3 0 0 1-.3-.299Zm7.95 2.373H9.45a.3.3 0 0 1-.3-.3v-.873a.3.3 0 0 1 .3-.3h1.275a.3.3 0 0 1 .3.3v.873a.3.3 0 0 1-.3.3ZM8.25 13.95H3.075a.3.3 0 0 1-.3-.3v-.839a.3.3 0 0 1 .3-.3H8.25a.3.3 0 0 1 .3.3v.839a.3.3 0 0 1-.3.3Zm2.775-1.139v.838a.3.3 0 0 1-.3.3H9.45a.3.3 0 0 1-.3-.3v-.838a.3.3 0 0 1 .3-.3h1.275a.3.3 0 0 1 .3.301ZM13.5 3.9h-2.4a1.2 1.2 0 0 1-1.2-1.2V.3a.3.3 0 0 1 .512-.212l3.3 3.3a.3.3 0 0 1-.212.512Z"
      />
    </svg>
  ),

  settingIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.01"
      height="18"
      viewBox="0 0 19.01 18"
    >
      <path
        fill="currentColor"
        d="M1.134 6.359A2.484 2.484 0 0 0 3.092 3a1.166 1.166 0 0 1 .349-1.312A9.347 9.347 0 0 1 6.229.073a1.172 1.172 0 0 1 1.324.368 2.478 2.478 0 0 0 .866.7 2.478 2.478 0 0 0 1.086.251 2.478 2.478 0 0 0 1.086-.251 2.478 2.478 0 0 0 .866-.7 1.172 1.172 0 0 1 1.324-.368 9.391 9.391 0 0 1 2.6 1.457 1.179 1.179 0 0 1 .368 1.35 2.471 2.471 0 0 0 2.059 3.4 1.172 1.172 0 0 1 1.027.944A8.967 8.967 0 0 1 19.01 9a9.07 9.07 0 0 1-.12 1.489 1.172 1.172 0 0 1-1.033.982 2.472 2.472 0 0 0-2.015 3.492 1.153 1.153 0 0 1-.323 1.381 9.416 9.416 0 0 1-2.763 1.59 1.267 1.267 0 0 1-.4.07 1.166 1.166 0 0 1-.951-.494 2.452 2.452 0 0 0-2.028-1.064 2.483 2.483 0 0 0-1.99 1 1.166 1.166 0 0 1-1.369.386 9.5 9.5 0 0 1-2.535-1.514 1.172 1.172 0 0 1-.342-1.337 2.471 2.471 0 0 0-1.983-3.415 1.172 1.172 0 0 1-.995-.963A9.188 9.188 0 0 1 0 9a9.093 9.093 0 0 1 .158-1.7 1.16 1.16 0 0 1 .976-.944ZM6.337 9a3.168 3.168 0 1 0 .928-2.24A3.168 3.168 0 0 0 6.337 9Z"
      />
    </svg>
  ),

  editIcon: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 13.75V10.5625L10.15 0.68125C10.3 0.54375 10.4656 0.4375 10.6469 0.3625C10.8281 0.2875 11.0188 0.25 11.2188 0.25C11.4187 0.25 11.6125 0.2875 11.8 0.3625C11.9875 0.4375 12.15 0.55 12.2875 0.7L13.3188 1.75C13.4688 1.8875 13.5781 2.05 13.6469 2.2375C13.7156 2.425 13.75 2.6125 13.75 2.8C13.75 3 13.7156 3.19062 13.6469 3.37187C13.5781 3.55312 13.4688 3.71875 13.3188 3.86875L3.4375 13.75H0.25ZM11.2 3.85L12.25 2.8L11.2 1.75L10.15 2.8L11.2 3.85Z"
        fill="currentColor"
      />
    </svg>
  ),

  deleteIcon: (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 13.75C1.8375 13.75 1.48437 13.6031 1.19062 13.3094C0.896875 13.0156 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1031 13.0156 10.8094 13.3094C10.5156 13.6031 10.1625 13.75 9.75 13.75H2.25ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
        fill="currentColor"
      />
    </svg>
  ),

  deleteIcon_2: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z"
        fill="#FB4D4D"
      />
    </svg>
  ),

  pouchEditIcon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M2.99878 17.4613V20.5013C2.99878 20.7813 3.21878 21.0013 3.49878 21.0013H6.53878C6.66878 21.0013 6.79878 20.9513 6.88878 20.8513L17.8088 9.94128L14.0588 6.19128L3.14878 17.1013C3.04878 17.2013 2.99878 17.3213 2.99878 17.4613ZM20.7088 7.04128C21.0988 6.65128 21.0988 6.02128 20.7088 5.63128L18.3688 3.29128C17.9788 2.90128 17.3488 2.90128 16.9588 3.29128L15.1288 5.12128L18.8788 8.87128L20.7088 7.04128Z"
        fill="#2F61A8"
      />{" "}
    </svg>
  ),

  pouchViewIcon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M13.404 3.00178C8.31401 2.86178 4.14401 6.95178 4.14401 12.0018H2.35401C1.90401 12.0018 1.68401 12.5418 2.00401 12.8518L4.79401 15.6518C4.99401 15.8518 5.30401 15.8518 5.50401 15.6518L8.29401 12.8518C8.60401 12.5418 8.38401 12.0018 7.93401 12.0018H6.14401C6.14401 8.10178 9.32401 4.95178 13.244 5.00178C16.964 5.05178 20.094 8.18178 20.144 11.9018C20.194 15.8118 17.044 19.0018 13.144 19.0018C11.534 19.0018 10.044 18.4518 8.86401 17.5218C8.46401 17.2118 7.90401 17.2418 7.54401 17.6018C7.12401 18.0218 7.15401 18.7318 7.62401 19.0918C9.14401 20.2918 11.054 21.0018 13.144 21.0018C18.194 21.0018 22.284 16.8318 22.144 11.7418C22.014 7.05178 18.094 3.13178 13.404 3.00178ZM12.894 8.00178C12.484 8.00178 12.144 8.34178 12.144 8.75178V12.4318C12.144 12.7818 12.334 13.1118 12.634 13.2918L15.754 15.1418C16.114 15.3518 16.574 15.2318 16.784 14.8818C16.994 14.5218 16.874 14.0618 16.524 13.8518L13.644 12.1418V8.74178C13.644 8.34178 13.304 8.00178 12.894 8.00178Z"
        fill="#2F61A8"
      />{" "}
    </svg>
  ),

  listEyeIcon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
        fill="#2F61A8"
      />
    </svg>
  ),
  pouchDeleteIcon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z"
        fill="#FB4D4D"
      />{" "}
    </svg>
  ),
  imagePreview: (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2037_3230"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <rect width="40" height="40" fill="#B7B7B7" />
      </mask>
      <g mask="url(#mask0_2037_3230)">
        <path
          d="M8.33333 35C7.41667 35 6.63194 34.6736 5.97917 34.0208C5.32639 33.3681 5 32.5833 5 31.6667V8.33333C5 7.41667 5.32639 6.63194 5.97917 5.97917C6.63194 5.32639 7.41667 5 8.33333 5H23.3333V8.33333H8.33333V31.6667H31.6667V16.6667H35V31.6667C35 32.5833 34.6736 33.3681 34.0208 34.0208C33.3681 34.6736 32.5833 35 31.6667 35H8.33333ZM28.3333 15V11.6667H25V8.33333H28.3333V5H31.6667V8.33333H35V11.6667H31.6667V15H28.3333ZM10 28.3333H30L23.75 20L18.75 26.6667L15 21.6667L10 28.3333Z"
          fill="#B7B7B7"
        />
      </g>
    </svg>
  ),
};

export default SvgIcons;

import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import LayoutComponent from "./LayoutComponent";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import routes from "./routes";
import Loading from "../components/Loading";
import { getWebsiteCMSPages } from "../services/cms.service";

const Routers = () => {
  const [cmsPages, setCMSPages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getWebsiteCMSPages().then((response) => {
      setCMSPages(response);
      setLoading(false);
    });
  }, []);

  return (
    <Routes>
      {loading ? (
        "Loading..."
      ) : (
        <>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <LayoutComponent layoutType={route.layoutType}>
                  {route.isPrivate ? (
                    <Suspense fallback={<Loading isLoading={true} />}>
                      <PrivateRoute path={route.path} element={route.element} />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<Loading isLoading={true} />}>
                      <PublicRoute path={route.path} element={route.element} />
                    </Suspense>
                  )}
                </LayoutComponent>
              }
            />
          ))}
          {cmsPages.map((page, index) => (
            <Route
              key={index}
              path={`/${page.slug}`}
              element={
                <LayoutComponent layoutType="Blank">
                  <div className="container mt-5 cms-page">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page.content,
                      }}
                    ></div>
                  </div>
                </LayoutComponent>
              }
            />
          ))}
        </>
      )}
    </Routes>
  );
};

export default Routers;

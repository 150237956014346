import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdOutlineArrowDropDown, MdOutlineArrowRight } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";

import { NavItems } from "./NavItems";
import "./sidebar.scss";

const Sidebar = ({ isActiveSidebar, toggleSidebarButton }) => {
  const navigate = useNavigate();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(null);

  const handleSubmenu = (idx) => {
    if (isSubMenuOpen === idx) {
      setIsSubMenuOpen(null);
    } else {
      setIsSubMenuOpen(idx);
    }
  };

  useEffect(() => {
    if (isActiveSidebar) {
      toggleSidebarButton();
    }
  }, [navigate]);

  return (
    <div className={`sidebarMenu ${isActiveSidebar ? "sidebarAction" : ""}`}>
      <Button
        variant="link"
        onClick={toggleSidebarButton}
        className="align-items-center bg-white border border-2 btn d-xl-flex h-20 justify-content-center mt-2 p-0 position-absolute rounded-circle start-100 top-0 translate-middle-x w-20 z-2 toogle-button d-none"
        aria-label="Toggle Sidebar Menu"
      >
        {isActiveSidebar ? (
          <IoIosArrowForward color="#ABABAB" size={12} />
        ) : (
          <IoIosArrowBack color="#ABABAB" size={12} />
        )}
      </Button>

      <Navbar
        bg="dark"
        data-bs-theme="dark"
        variant="dark"
        expand="xxl"
        className="w-100 h-100 p-0"
      >
        <div className="d-flex flex-column w-100 h-100">
          <div className="overflow-x-hidden overflow-y-auto sidebarList h-100">
            <Nav defaultActiveKey="/" as="ul" className="flex-column p-2">
              {NavItems.map((elem) => {
                const { id, menuName, title, menuIcon, path, items, disabled } =
                  elem;
                return (
                  <Nav.Item as="li" key={id}>
                    {!items && (
                      <Nav.Link
                        key={menuName}
                        id={id}
                        as={NavLink}
                        to={path}
                        className={`align-items-center d-flex px-0 sidebarLink text-nowrap rounded ${
                          disabled ? "disabled opacity-25" : ""
                        }`}
                        onClick={() => setIsSubMenuOpen(null)}
                      >
                        <span className="py-1 text-center min-w-44 sidebarIcon">
                          {menuIcon}
                        </span>
                        <span className="hideInSmallSidebar">{title}</span>
                      </Nav.Link>
                    )}
                    {items && (
                      <Nav.Link
                        key={menuName}
                        as={Button}
                        variant="link"
                        onClick={() => handleSubmenu(id)}
                        className={`align-items-center d-flex px-0 sidebarLink text-nowrap rounded w-100 ${
                          isSubMenuOpen === id ? "active" : ""
                        } ${disabled ? "disabled opacity-25" : ""}`}
                      >
                        <span className="py-1 text-center min-w-44 sidebarIcon">
                          {menuIcon}
                        </span>
                        <span className="hideInSmallSidebar">{title}</span>
                        <span className="ms-auto sub-menu-arrow">
                          {isSubMenuOpen === id ? (
                            <MdOutlineArrowRight size={18} />
                          ) : (
                            <MdOutlineArrowDropDown size={18} />
                          )}
                        </span>
                      </Nav.Link>
                    )}
                    {isSubMenuOpen === id && items && (
                      <Nav as="ul" className="flex-column p-0">
                        {items.map((subItems) => {
                          return (
                            <Nav.Item as="li" key={subItems.id}>
                              <Nav.Link
                                key={subItems.menuName}
                                as={NavLink}
                                to={subItems.path}
                                className={`align-items-center d-flex px-0 sidebarLink text-nowrap rounded ${
                                  subItems.disabled ? "disabled opacity-25" : ""
                                }`}
                              >
                                <span className="py-1 text-center min-w-44 sidebarIcon d-none ps-1">
                                  {subItems.menuIcon}
                                </span>
                                <span className="hideInSmallSidebar">
                                  {subItems.title}
                                </span>
                              </Nav.Link>
                            </Nav.Item>
                          );
                        })}
                      </Nav>
                    )}
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
        </div>
      </Navbar>
    </div>
  );
};
export default Sidebar;

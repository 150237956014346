export const EDIT_CONFIRMATION_ALERT = {
  title: "Are you sure?",
  text: "You will not be able to recover this data!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonText: "Yes, delete it!",
  cancelButtonText: "No, cancel!",
  reverseButtons: true,
};

export const DELETE_CONFIRMATION_ALERT = {
  title: "Are you sure?",
  text: "You will not be able to recover this data!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonText: "Yes, delete it!",
  cancelButtonText: "No, cancel!",
  reverseButtons: true,
};

export const DELETE_DONE_CONFIRMATION = {
  title: "Deleted!",
  text: "Your data has been deleted.",
  icon: "success",
  showCancelButton: false,
  confirmButtonText: "Ok",
  reverseButtons: true,
};

export const DELETE_CANCEL_CONFIRMATION = {
  title: "Cancelled",
  text: "Your data is safe :)",
  icon: "error",
  showCancelButton: false,
  confirmButtonText: "Ok",
  reverseButtons: true,
};

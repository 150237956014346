import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { handleGetGraphData } from "../../../services/dashboard.service";

import SvgIcons from "../../../components/SVGIcons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = () => {
  const [date, setDate] = useState(new Date());
  const ref = React.createRef();

  const [data, setData] = useState({
    datasets: [],
    labels: [],
  });

  useEffect(() => {
    handleGetGraphData().then((response) => {
      setData(response.data);
    });
  }, []);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Card className="h-100 border-0 shadow-sm py-3">
      <Card.Header as="h6" className="fw-semibold bg-body border-0">
        <div className="d-md-flex w-100 justify-content-between align-items-center">
          <div className="mb-2 fs-20">New Users</div>
          <div className="d-md-flex align-items-center">
            <div className="d-md-flex gap-4">
              <div className="fs-13 fw-normal mb-2">
                <span className="on_trail_box"></span>On Trial
              </div>
              <div className="fs-13 fw-normal mb-2">
                <span className="paid_box"></span>Paid Users
              </div>
              <div className="fs-13 fw-normal me-5 mb-2">
                <span className="free_box"></span>Students
              </div>
            </div>

            <div className="form-floating m-0 position-relative form-control__calendar">
              <input
                className="form-control"
                placeholder="Date Range"
                value={moment(date).format("MMM yyyy")}
                onClick={() => {
                  ref.current.setOpen(true);
                }}
              />
              <DatePicker
                ref={ref}
                className="form-control d-none h-0"
                selected={date}
                onChange={(selectedDate) => {
                  setDate(selectedDate);
                  handleGetGraphData({
                    date: selectedDate,
                  }).then((response) => {
                    setData(response.data);
                  });
                }}
                dateFormat="MM/yyyy"
                //  maxDate={new Date()}
                // excludeDates={[new Date("2024-05-01"), new Date("2024-06-01")]}
                showMonthYearPicker
              />
              <span className="">{SvgIcons.calendarIcon}</span>
              <label>Filter</label>
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Line options={options} data={data} />
      </Card.Body>
    </Card>
  );
};

export default LineChart;

import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card } from "reactstrap";

import { handleGetTransactions } from "../../../services/transaction.service";
import UserTransactionsTable from "../UsersTable";
import SearchForm from "./SearchForm";
import moment from "moment";
import Header from "../../common/Header";

export default function TransactionList() {
  const location = useLocation();
  const parameter = useParams();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  console.log("parameter", parameter);

  const [pagination, setPagination] = React.useState({
    pageIndex: params.page ? parseInt(params.page) - 1 : 0,
    pageSize: params.limit ? parseInt(params.limit) : 10,
  });

  const [sorting, setSorting] = React.useState([]);

  const [filter, setFilter] = React.useState({
    search: "",
    userId: parameter.userId,
  });

  const dataQuery = useQuery({
    queryKey: ["data", pagination, sorting, filter],
    queryFn: () => {
      const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
      Object.keys(filterObj).forEach(
        (key) => filterObj[key] === "" && delete filterObj[key]
      );

      if (sorting.length === 0) {
        return handleGetTransactions({
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          ...filterObj,
        });
      } else {
        return handleGetTransactions({
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          sortBy: sorting
            .map((sort) => `${sort.id}:${sort.desc ? "desc" : "asc"}`)
            .join(","),
          ...filterObj,
        });
      }
    },
  });

  //handle last page deletion item
  useEffect(() => {
    if (dataQuery.data?.data?.totalPages < pagination.pageIndex + 1) {
      setPagination({
        pageIndex: dataQuery.data?.data?.totalPages - 1,
        pageSize: 10,
      });
    }
  }, [dataQuery.data?.data?.totalPages]);

  const columns = React.useMemo(
    () => [
      {
        accessorFn: (row) => row.transactionType,
        id: "type",
        header: () => "Transaction Type",
        cell: (info) => {
          return <span>{info.row.original.type}</span>;
        },
      },
      {
        accessorFn: (row) => row.createdAt,
        id: "createdAt",
        header: () => "Transaction Date",
        cell: (info) => {
          return (
            <span>
              {moment(info.row.original.createdAt).format("D/M/YYYY")}
            </span>
          );
        },
      },

      {
        accessorFn: (row) => row.period,
        id: "period",
        header: () => "Period",
        enableSorting: false,
        cell: (info) => {
          return (
            <span>
              {moment(info.row.original.startDate).format("D/M/YYYY")} - {" "}
              {moment(info.row.original.endDate).format("D/M/YYYY")}
            </span>
          );
        },
      },

      {
        accessorFn: (row) => row.localizedAmount,
        id: "localizedAmount",
        header: () => "Amount",
        enableSorting: false,
      },
    ],
    []
  );

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [filter]);

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Header showAddButton={false} title={"Manage Users"} />
      <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
        <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
          <SearchForm filter={filter} setFilter={setFilter} />
          <UserTransactionsTable
            columns={columns}
            dataQuery={dataQuery}
            pagination={pagination}
            setPagination={setPagination}
            sorting={sorting}
            setSorting={setSorting}
          />
        </Card>
      </div>
    </div>
  );
}

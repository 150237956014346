import { MdDashboard } from "react-icons/md";

import SvgIcons from "../../components/SVGIcons";

export const NavItems = [
  {
    id: 1,
    menuName: "dashboard",
    title: "Dashboard",
    menuIcon: <MdDashboard size={14} />,
    path: "/",
    disabled: false,
  },
  {
    id: 2,
    menuName: "Manage Users",
    title: "Manage Users",
    menuIcon: SvgIcons.userManagementIcon,
    path: "/users",
    disabled: false,
  },
  {
    id: 3,
    menuName: "Manage Templates",
    title: "Manage Templates",
    menuIcon: SvgIcons.manageTemplate,
    path: "/templates",
    disabled: false,
  },
  // {
  //   id: 4,
  //   menuName: "LLM Connection",
  //   title: "LLM Connection",
  //   menuIcon: SvgIcons.llmConnection,
  //   path: "/llm-connection",
  //   disabled: true
  // },
  {
    id: 5,
    menuName: "Goals",
    title: "Goals",
    menuIcon: SvgIcons.goalsIcon,
    path: "/goals",
    disabled: false,
  },

  {
    id: 6,
    menuName: "other-settings",
    title: "Settings",
    menuIcon: SvgIcons.settings,
    path: "/other-settings",
    disabled: false,
  },
  {
    id: 7,
    menuName: "CMS",
    title: "CMS",
    menuIcon: SvgIcons.cms,
    path: "/cms",
    disabled: false,
  },
  {
    id: 8,
    menuName: "Saving Challenges",
    title: "Saving Challenges",
    menuIcon: SvgIcons.savingChallenges,
    path: "/saving-challenges",
  },
  // {
  //   id: 8,
  //   menuName: "Pouch Score",
  //   title: "Pouch Score",
  //   menuIcon: SvgIcons.savingChallenges,
  //   path: "/pouch-score-calculation",
  // },
];

import instance from "../utils/instance";

export const handleGetProfile = async (params) => {
  return await instance.get("/v1/profile", {
    params,
  });
};

export const handleUpdateProfile = async (data) => {
  return await instance.post("/v1/profile", data);
};

import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import UsersTable from "./UsersTable";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { getUserChallengesById } from "../../services/user.service";

const ChallengeModal = ({
  showModal,
  modal,
  toggle,
  isEditMode,
  selectedGoal,
}) => {
  const [sorting, setSorting] = React.useState([]);

  /**
   * Fetches user challenges based on the modal ID using React Query.
   *
   * @constant
   * @type {object}
   * @name dataQuery
   *
   * @property {Array} queryKey - An array representing the query key, here it's ["data"].
   * @property {function} queryFn - A function that returns a promise. If the modal is present, it calls `getUserChallengesById` with the modal ID; otherwise, it returns an empty object.
   *
   */
  const dataQuery = useQuery({
    queryKey: ["data"],
    queryFn: () => {
      if (modal) {
        return getUserChallengesById(modal?.id);
      }
      return {};
    },
  });

  const columns = React.useMemo(() => [
    {
      accessorFn: (row) => row.id,
      id: "id",
      header: () => "Id",
      enableSorting: false,
    },

    {
      accessorFn: (row) => row.title,
      id: "title",
      header: () => "Title",
      enableSorting: false,
    },
    {
      accessorFn: (row) => (row.completed ? "Yes" : "No"),
      id: "Completed",
      header: () => "Completed",
      enableSorting: false,
    },
    {
      accessorFn: (row) => (row.status === "closed" ? "Closed" : "Open"),
      id: "status",
      header: () => "Staus",
      enableSorting: false,
    },
    {
      accessorFn: (row) => row.lastActivity,
      id: "lastActivity",
      header: () => "End Date",
      cell: (info) => {
        return <span>{moment(info.row.original.endDate).format("D/M/YYYY")}</span>;
      },
      enableSorting: false,
    },
  ]);

  /**
   * Effect hook to refetch data when the modal changes.
   *
   * This hook runs whenever the `modal` dependency changes. If `modal` is defined,
   * it triggers a refetch of the data by calling `dataQuery.refetch()`.
   */
  useEffect(() => {
    if (modal !== undefined) {
      dataQuery.refetch();
    }
  }, [modal]);

  return (
    <Modal
      size="lg"
      className="goal-modal-cover"
      isOpen={showModal}
      toggle={toggle}
      centered
    >
      <ModalHeader className="border-0 fs-16 fw-semibold" toggle={toggle}>
        User Challenges
      </ModalHeader>
      <ModalBody>
        <UsersTable
          columns={columns}
          dataQuery={dataQuery}
          pagination={false}
          setPagination={false}
          sorting={sorting}
          setSorting={setSorting}
          showNavigationButtons={false}
        />
      </ModalBody>
      {/* <ModalFooter className="border-0 pt-0">
        <Button
          className=" btn fs-14 fw-semibold mw-44"
          size="lg"
          variant="primary"
          onClick={(e) => toggle(null)}
        >
          {" "}
          Ok{" "}
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};

export default ChallengeModal;

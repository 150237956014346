import React from "react";
import Input from "./Input";

import "./FormInput.scss";

export default function FormInput({
  label,
  touched,
  error,
  className = "mb-2",
  ...rest
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <div class={`form-floating position-relative ${className}`}>
      {rest.type === "password" ? (
        <>
          <Input
            className={`form-control ${touched && error ? "is-invalid" : ""}`}
            {...rest}
            type={rest.type === "password" && showPassword ? "text" : rest.type}
          />
          <label htmlFor={rest.id}>{label}</label>
          {rest.type === "password" && rest.value.length > 0 ? (
            <i
              className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"} password-icon`}
              onClick={() => setShowPassword(!showPassword)}
            ></i>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          <Input
            maxLength={255}
            className={`form-control ${touched && error ? "is-invalid" : ""}`}
            {...rest}
            type={rest.type === "password" && showPassword ? "text" : rest.type}
          />
          <label htmlFor={rest.id}>{label}</label>
        </>
      )}
      {touched && error && (
        <small id="emailHelp" className="form-text text-danger">
          {error}
        </small>
      )}
    </div>
  );
}

import { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import {
  handleLogin,
  handleRefreshToken,
} from "../services/authentication.service";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../utils/storage";

export const AuthenticationContext = createContext({
  isAuthenticated: false,
  isLoading: true,
  user: {
    name: "Super Admin",
  },
});

export default function AuthenticationProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({
    name: "Super Admin",
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const localStorageRefreshToken = getLocalStorage("refresh_token");
    if (!!localStorageRefreshToken) {
      refreshToken(localStorageRefreshToken);
    }
  }, []);

  useEffect(() => {
    if (!!getLocalStorage("access_token")) {
      setIsAuthenticated(true);
      setIsLoading(false);
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, []);

  const logout = () => {
    removeLocalStorage("access_token");
    removeLocalStorage("refresh_token");
    setIsAuthenticated(false);
  };

  const login = async (data) => {
    return handleLogin(data)
      .then((response) => {
        setLocalStorage("access_token", response.data.tokens.access.token);
        setLocalStorage("refresh_token", response.data.tokens.refresh.token);
        setUser(response.data.user);
        setIsAuthenticated(true);
        navigate("/");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Error");
      });
  };

  const refreshToken = async (localStorageRefreshToken) => {
    return handleRefreshToken(localStorageRefreshToken)
      .then((response) => {
        setLocalStorage("access_token", response.data.tokens.access.token);
        setLocalStorage("refresh_token", response.data.tokens.refresh.token);

        setUser(response.data.user);
        setIsAuthenticated(true);
        navigate(location.pathname);
      })
      .catch((error) => {
        removeLocalStorage("access_token");
        removeLocalStorage("refresh_token");
        setIsAuthenticated(false);
      });
  };

  const updateUser = (data) => {
    setUser(data);
  };

  useEffect(() => {}, [isAuthenticated]);

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        setIsAuthenticated,
        logout,
        login,
        user,
        updateUser,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";

import FormInput from "../../components/FormInput";
import { handleChangePassword } from "../../services/authentication.service";
import { validationSchema } from "../../validations/changePassword.validation";
import { HiMiniUsers } from "react-icons/hi2";
import "./ChangePassword.scss";
import { Button, Card, CardFooter, Stack } from "react-bootstrap";

export default function ChangePassword() {
  const onSubmit = async (values, actions) => {
    await handleChangePassword(values)
      .then((response) => {
        toast.success(response.data.message);
        actions.resetForm();
      })
      .catch((error) => {
        actions.resetForm();
      });
  };

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <div className="contentHeader p-1">
        <Stack
          direction="horizontal"
          gap={2}
          className="flex-wrap justify-content-between custom-min-height-42"
        >
          <h1 className="fw-semibold h4 mb-0 fs-22">Change Password</h1>
        </Stack>
      </div>
      <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
        <Formik
          initialValues={{
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  gap={3}
                  className="flex-wrap px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fw-semibold fs-16">
                    <div className="align-items-center bg-info d-inline-flex custom-height-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box custom-width-60 z-1">
                      <HiMiniUsers size={32} />
                    </div>
                    Change Password
                  </h5>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-3 pb-1 overflow-auto">
                <div className="p-1 ">
                  <div className="">
                    <div className="col-md-4 mt-2">
                      <div className="content px-2">
                        <>
                          <FormInput
                            error={errors.newPassword}
                            id="newPassword"
                            key={"newPassword"}
                            label="New Password"
                            name="newPassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter New password"
                            touched={touched.newPassword}
                            type="password"
                            value={values.newPassword}
                          />

                          <FormInput
                            error={errors.confirmPassword}
                            id="confirmPassword"
                            key={"confirmPassword"}
                            label="Confirm Password"
                            name="confirmPassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter confirm password"
                            touched={touched.confirmPassword}
                            type="password"
                            value={values.confirmPassword}
                          />
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <CardFooter className="">
                <div className="d-flex justify-content-end">
                  <Button
                    className="fs-14 fw-semibold"
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Change Password
                  </Button>
                </div>
              </CardFooter>
            </Card>
          )}
        </Formik>
      </div>
    </div>
  );
}

import React from "react";

import HomePage from "../pages/dashboard";
import NotFoundPage from "../pages/common/NotFoundPage";
import Login from "../pages/authentication/Login";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import ResetPassword from "../pages/authentication/ResetPassword";
import ChangePassword from "../pages/Profile/ChangePassword";
import UserList from "../pages/users";
import TransactionList from "../pages/users/transactions";
import GoalsList from "../pages/goals";
import PouchScoreCalculation from "../pages/pouchScoreCalculation";
import Profile from "../pages/Profile/Profile";

const Templates = React.lazy(() => import("../pages/templates"));
const Cms = React.lazy(() => import("../pages/cms"));
const PouchSettings = React.lazy(() => import("../pages/pouchSettings"));
const BonusSettings = React.lazy(() => import("../pages/pouchSettings/bonus"));
const Notification = React.lazy(() =>
  import("../pages/pouchSettings/notification")
);
const SubscriptionSettings = React.lazy(() =>
  import("../pages/pouchSettings/subscription")
);
const AddEditCms = React.lazy(() => import("../pages/cms/addEdit"));
const AddEditTemplate = React.lazy(() => import("../pages/templates/addEdit"));
const ViewTemplate = React.lazy(() => import("../pages/templates/view"));
const UserForm = React.lazy(() => import("../pages/users/Edit"));
const LlmConnection = React.lazy(() => import("../pages/llm-connection"));
const Settings = React.lazy(() => import("../pages/settings"));
const UserTransactions = React.lazy(() =>
  import("../pages/users/transactions")
);

// Saving Challenges
const SavingChallenges = React.lazy(() => import("../pages/saving-challenges"));
const AddEditSavingChallenges = React.lazy(() =>
  import("../pages/saving-challenges/addEdit")
);

const routes = [
  {
    path: "/",
    element: <HomePage />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/login",
    element: <Login />,
    isPrivate: false,
    layoutType: "Blank",
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    isPrivate: false,
    layoutType: "Blank",
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    isPrivate: false,
    layoutType: "Blank",
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
    isPrivate: true,
    layoutType: "Auth",
  },

  {
    path: "/users",
    element: <UserList />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "users/:userId/transactions",
    element: <TransactionList />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/goals",
    element: <GoalsList />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/templates",
    element: <Templates />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/templates/add",
    element: <AddEditTemplate isEdit={false} />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/templates/edit/:id",
    element: <AddEditTemplate isEdit={true} />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/templates/edit/:id",
    element: <AddEditTemplate isEdit={true} />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/templates/view/:id",
    element: <ViewTemplate />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/llm-connection",
    element: <LlmConnection />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/users/:id",
    element: <UserForm isEdit={true} />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/users/:id/transactions",
    element: <UserTransactions />,
    isPrivate: true,
    layoutType: "Auth",
  },

  {
    path: "/settings",
    element: <Settings />,
    isPrivate: true,
    layoutType: "Auth",
  },

  {
    path: "/other-settings",
    element: <PouchSettings />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/other-settings/bonus",
    element: <BonusSettings />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/other-settings/notification",
    element: <Notification />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/other-settings/subscription",
    element: <SubscriptionSettings />,
    isPrivate: true,
    layoutType: "Auth",
  },

  {
    path: "/cms",
    element: <Cms />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/cms/edit/:id",
    element: <AddEditCms isEdit={true} />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/saving-challenges",
    element: <SavingChallenges />,
    isPrivate: true,
    layoutType: "Auth",
  },

  {
    path: "/saving-challenges/add",
    element: <AddEditSavingChallenges isEdit={false} />,
    isPrivate: true,
    layoutType: "Auth",
  },

  {
    path: "/saving-challenges/edit/:id",
    element: <AddEditSavingChallenges isEdit={true} />,
    isPrivate: true,
    layoutType: "Auth",
  },

  {
    path: "/pouch-score/:userId",
    element: <PouchScoreCalculation />,
    isPrivate: true,
    layoutType: "Auth",
  },
  {
    path: "/profile",
    element: <Profile />,
    isPrivate: true,
    layoutType: "Auth",
  },

  {
    path: "*",
    element: <NotFoundPage />,
    isPrivate: false,
    layoutType: "Blank",
  },
];

export default routes;

import instance from "../utils/instance";

export const handleGetGoals = async (params) => {
  return await instance.get("/v1/goals", {
    params,
  });
};

export const handleAddGoal = async (data) => {
  return await instance.post(`/v1/goals`, data);
};

export const handleDeleteGoal = async (id) => {
  return await instance.delete(`/v1/goals/${id}`);
};

export const handleUpdateGoal = async (id, data) => {
  return await instance.patch(`/v1/goals/${id}`, data);
};

export const handleGetGoal = async (id) => {
  return await instance.get(`/v1/goals/${id}`);
};

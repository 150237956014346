import * as Yup from "yup";

const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required.")
    .matches(/^[^\d]+$/, "Name must not contain numbers.")
    .max(50, "Name is too long."),
  email: Yup.string()
    .matches(emailRegExp, "Email must be a valid email")
    .required("Email is required."),
});

export { validationSchema };

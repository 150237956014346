import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card } from "reactstrap";

import AppTooltip from "../../components/AppTooltip";
import SvgIcons from "../../components/SVGIcons";
import { handleGetGoal, handleGetGoals } from "../../services/goal.service";
import GoalsModal from "./GoalModal";
import GoalsTable from "./GoalsTable";
import Header from "./Header";
import SearchForm from "./SearchForm";

export default function GoalsList() {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [pagination, setPagination] = useState({
    pageIndex: params.page ? parseInt(params.page) - 1 : 0,
    pageSize: params.limit ? parseInt(params.limit) : 10,
  });
  const [modal, setModal] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
  });
  const [selectedGoal, setSelectedGoal] = useState(null);

  const toggle = () => {
    if (modal) {
      setSelectedGoal(null);
    }
    setModal(!modal);
  };

  const dataQuery = useQuery({
    queryKey: ["data", pagination, sorting, filter],
    queryFn: () => {
      const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
      Object.keys(filterObj).forEach(
        (key) => filterObj[key] === "" && delete filterObj[key]
      );

      if (sorting.length === 0) {
        return handleGetGoals({
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          ...filterObj,
        });
      } else {
        return handleGetGoals({
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          sortBy: sorting
            .map((sort) => `${sort.id}:${sort.desc ? "desc" : "asc"}`)
            .join(","),
          ...filterObj,
        });
      }
    },
  });

  useEffect(() => {
    if (dataQuery.data?.data?.totalPages < pagination.pageIndex + 1) {
      setPagination({
        pageIndex: dataQuery.data?.data?.totalPages - 1,
        pageSize: 10,
      });
    }
  }, [dataQuery.data?.data?.totalPages]);

  const columns = React.useMemo(
    () => [
      {
        accessorFn: (row) => row.title,
        id: "title",
        header: () => "Goals",
      },
      {
        accessorFn: (row) => row.amount,
        id: "amount",
        header: () => "Amount",
      },
      {
        id: "actions",
        isAction: true,
        cell: (info) => {
          return (
            <div className="d-flex items-center gap-2 justify-content-center">
              <AppTooltip title="Edit" placement="top">
                <Link
                  to="#"
                  onClick={() => {
                    handleGetGoal(info.row.original.id).then((response) => {
                      setSelectedGoal(response.data.data);
                      toggle();
                    });
                  }}
                  className="btn btn-link text-secondary lh-1 custom-width-18 custom-height-18 p-0 d-inline-flex align-items-center justify-content-center"
                  aria-label="Edit"
                >
                  {SvgIcons.pouchEditIcon}
                </Link>
              </AppTooltip>
            </div>
          );
        },
        header: () => (
          <div className="d-flex justify-content-center">Actions</div>
        ),
        enableSorting: false,
      },
    ],
    []
  );

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [filter]);

  return (
    <React.Fragment>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <Header toggle={toggle} />
        <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
          <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
            <SearchForm filter={filter} setFilter={setFilter} />
            <GoalsTable
              columns={columns}
              dataQuery={dataQuery}
              pagination={pagination}
              setPagination={setPagination}
              sorting={sorting}
              setSorting={setSorting}
            />
          </Card>
        </div>
      </div>

      <GoalsModal
        modal={modal}
        toggle={toggle}
        isEditMode={!!selectedGoal}
        selectedGoal={selectedGoal}
        dataQuery={dataQuery}
      />
    </React.Fragment>
  );
}

import instance from "../utils/instance";

export const handleGetTotalNewUsers = async () => {
  try {
    const response = await instance.get("v1/dashboard/total-new-users");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const handleGetNewUsersOnTrial = async () => {
  try {
    const response = await instance.get("v1/dashboard/new-users-on-trial");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const handleGetNewUsersOnPaid = async () => {
  try {
    const response = await instance.get("v1/dashboard/new-users-on-paid");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const handleGetNewUsersAsStudents = async () => {
  try {
    const response = await instance.get("v1/dashboard/new-users-as-students");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const handleGetActiveUsers = async () => {
  try {
    const response = await instance.get("v1/dashboard/active-users");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const handleGetTotalRegisteredUsers = async () => {
  try {
    const response = await instance.get("v1/dashboard/total-registered-users");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const handleGetGraphData = async (data) => {
  try {
    const response = await instance.post("v1/dashboard", data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const handleGetRevenue = async (data) => {
  try {
    const response = await instance.get("v1/dashboard/revenue", {
      params: data,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const handleGetCustomerChurnRate = async () => {
  try {
    const response = await instance.get("v1/dashboard/customer-churn-rate");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AppTooltip = ({ children, title, placement }) => {
  return (
    <OverlayTrigger
      delay={{ hide: 250, show: 100 }}
      trigger={["hover", "hover"]}
      overlay={(props) => <Tooltip {...props}>{title}</Tooltip>}
      placement={placement || "auto"}
    >
      {children}
    </OverlayTrigger>
  );
};

export default AppTooltip;

import PropTypes from "prop-types";
import React from "react";
import { Stack } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const Header = ({ title, addBtnTitle, showAddButton = true }) => {
  const location = useLocation();

  return (
    <div className="contentHeader p-1">
      <Stack
        direction="horizontal"
        gap={2}
        className="flex-wrap justify-content-between custom-min-height-42"
      >
        <h1 className="fw-semibold h4 mb-0 fs-22  pb-3 pt-1"> {title} </h1>
        {showAddButton ? (
          <Link
            to={`${location.pathname}/add`}
            className="fw-semibold mw-44 btn btn-primary btn-sm align-items-center d-flex"
            type="button"
            variant="primary"
            size="sm"
          >
            {addBtnTitle}
          </Link>
        ) : (
          ""
        )}
      </Stack>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  addBtnTitle: PropTypes.string,
  showAddButton: PropTypes.bool,
};

export default Header;

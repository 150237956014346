import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table as BTable } from "reactstrap";

import PropTypes from "prop-types";
import SortArrow from "../assets/svg/sort-arrow.svg";
import DataGridPagination from "./Datagridpagination";
import "./ReactTable.scss";

/**
 * ReactTable component to display data in a table format with pagination and sorting capabilities.
 *
 * @param {Object} props - The properties object.
 * @param {any} props.columns - Configuration for the table columns.
 * @param {any} props.dataQuery - Query object or function to fetch data for the table.
 * @param {Function} props.setPagination - Function to update the pagination state.
 * @param {Function} props.setSorting - Function to update the sorting state.
 * @param {any} props.pagination - Current pagination state.
 * @param {any} props.sorting - Current sorting state.
 * @returns {JSX.Element} The rendered ReactTable component.
 */
export default function ReactTable({
  columns,
  dataQuery,
  setPagination,
  setSorting,
  pagination,
  sorting,
  showNavigationButtons = true,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultData = React.useMemo(() => [], []);

  /**
   * Configuration for the ReactTable instance.
   *
   * @typedef {Object} ReactTableConfig
   * @property {Array} data - The data to be displayed in the table.
   * @property {Array} columns - Configuration for the table columns.
   * @property {number} rowCount - Total number of rows in the data.
   * @property {Object} state - The current state of the table.
   * @property {Object} state.pagination - The current pagination state.
   * @property {Object} state.sorting - The current sorting state.
   * @property {Function} onSortingChange - Function to update the sorting state.
   * @property {Function} onPaginationChange - Function to update the pagination state.
   * @property {Function} getCoreRowModel - Function to get the core row model.
   * @property {boolean} manualPagination - Flag to indicate manual pagination.
   * @property {boolean} manualSorting - Flag to indicate manual sorting.
   * @property {Function} isMultiSortEvent - Function to determine if an event is a multi-sort event.
   * @property {boolean} debugTable - Flag to enable table debugging.
   * @property {boolean} enableSortingRemoval - Flag to enable sorting removal.
   */
  const table = useReactTable({
    data: dataQuery.data?.data?.data ?? defaultData,
    columns,
    rowCount: dataQuery.data?.rowCount,
    state: { pagination, sorting },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    isMultiSortEvent: (e) => true,
    debugTable: true,
    enableSortingRemoval: true,
  });

  React.useEffect(() => {
    let path = "";
    if (sorting.length === 0) {
      path = `${location.pathname}?page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}`;
    } else {
      path = `${location.pathname}?page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&sortBy=${sorting.map((sort) => `${sort.id}:${sort.desc ? "desc" : "asc"}`).join(",")}`;
    }
    navigate(path);
  }, [sorting, pagination]);

  return (
    <>
      {dataQuery?.data?.data?.totalPages > 0 ? (
        <BTable striped bordered hover responsive size="sm">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          className={
                            header.column.getCanSort()
                              ? "cursor-pointer select-none position-relative d-flex"
                              : ""
                          }
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === "asc"
                                ? "Sort ascending"
                                : header.column.getNextSortingOrder() === "desc"
                                  ? "Sort descending"
                                  : "Clear sort"
                              : undefined
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                          {{
                            asc: <img className="sort-asc" src={SortArrow} />,
                            desc: <img className="sort-desc" src={SortArrow} />,
                          }[header.column.getIsSorted()] ?? null}
                          {/* </div> */}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} align={cell.column.columnDef.meta?.align}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </BTable>
      ) : (
        <BTable striped bordered hover responsive size="sm">
          <tbody>
            <tr>
              <th colSpan={columns?.length || 1} className="text-center">
                No Data Found
              </th>
            </tr>
          </tbody>
        </BTable>
      )}
      {dataQuery?.data?.data?.totalPages > 0 && showNavigationButtons ? (
        <div className="">
          <DataGridPagination
            rowsPerPage={pagination.pageSize}
            currentPage={pagination.pageIndex + 1}
            totalPages={dataQuery.data?.data?.totalPages}
            totalRecords={dataQuery.data?.data?.totalResults}
            setCurrentPage={(selected) => {
              table.setPagination({ ...pagination, pageIndex: selected - 1 });
            }}
            setLimit={(limit) => {
              table.setPagination({ pageIndex: 0, pageSize: limit });
            }}
            currentTotalRecord={10}
            startIndex={dataQuery.data?.data?.startIndex}
            endIndex={dataQuery.data?.data?.endIndex}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

/**
 * Prop types for the ReactTable component.
 *
 * @typedef {Object} ReactTablePropTypes
 * @property {any} columns - Configuration for the table columns.
 * @property {any} dataQuery - Query object or function to fetch data for the table.
 * @property {any} setPagination - Function to update the pagination state.
 * @property {any} setSorting - Function to update the sorting state.
 * @property {any} pagination - Current pagination state.
 * @property {any} sorting - Current sorting state.
 */
ReactTable.propTypes = {
  columns: PropTypes.any,
  dataQuery: PropTypes.any,
  setPagination: PropTypes.any,
  setSorting: PropTypes.any,
  pagination: PropTypes.any,
  sorting: PropTypes.any,
  showNavigationButtons: PropTypes.bool,
};
